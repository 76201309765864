.holoText {
  background: linear-gradient(45deg, var(--holo-long));
	background-size: 300% 300%;
	animation: gradient 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	font-smooth: always;
	
}

.btnMenu {
	 position: relative;
   width: 30px;
   height: 30px;
   pointer: cursor;
}
 .btnMenu i.bar {
	 height: 3px;
	 width: 30px;
	 position: absolute;
	 background-color: white;
}
 .btnMenu:hover i.bar {
	 background: var(--holo-bg);
}
 .btnMenu i.bar:nth-child(1) {
	 top: 0;
	 left: 0;
}
 .btnMenu i.bar:nth-child(2) {
	 top: 42%;
	 left: 0;
}
 .btnMenu i.bar:nth-child(3) {
	 bottom: 0;
	 left: 0;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
  50% {
		background-position: 50% 0%;
	}
	100% {
		background-position: 0% 50%;
	}
}