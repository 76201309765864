.HomeProjectCard {
  background-size: 150%;
  background-position: -63px -15px;
  cursor: pointer;
  width: 100%;
}

.HomeBusinessVenturesCard {
  background-size: 140%;
  cursor: pointer;
  background-position: 85% 60p;
  width: 100%;
}

@media (min-width: 1024px) {
  .HomeProjectCard {
    background-position: -40px -140px;
    background-size: 125%;
  }
}

@media (min-width: 768px) {
  .HomeProjectCard {
    background-position: -60px -140px;
    background-size: 125%;
  }

  .HomeBusinessVenturesCard {
    background-size: cover;
    background-position: 45% 60px;
  }
}
