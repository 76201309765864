.holoText {
  background: var(--holo-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.holo {
  background: var(--holo-long);
	animation: gradient 5s ease infinite;
  background-size: 400% 400%;
}

.holoBtn {
	background: linear-gradient(45deg, var(--holo-wide-bg)) center / cover repeat
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
  50% {
		background-position: 50% 0%;
	}
	100% {
		background-position: 0% 50%;
	}
}