
@keyframes gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}

@keyframes path-in {
  100% {
      clip-path: polygon(0% -1%, 0 100%, 1% 100%, 1% 3%, 1% 3%, 1% 95%, 0 95%, 0 100%, 100% 100%, 100% -1%);
  }
}

@keyframes path-out {
  0% {
      clip-path: polygon(0% -1%, 0 100%, 1% 100%, 1% 3%, 1% 3%, 1% 95%, 0 95%, 0 100%, 100% 100%, 100% -1%);
  }

  100% {
      clip-path: polygon(0% -1%, 0 100%, 1% 100%, 1% 3%, 99% 3%, 99% 95%, 0 95%, 0 100%, 100% 100%, 100% -1%);
  }
}

.svgShadow  {
 filter:  drop-shadow(0px 0px 20px rgba(38, 38, 38, 0.7));
}


.btnChromaBorder {
  border: none;
  position: relative;
  z-index: 1;
}

.btnChromaBorder:hover {
  @apply text-stone-900;
  z-index: 1;
}

.btnChromaBorder::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  clip-path: polygon(0% -1%, 0 100%, 1% 100%, 1% 3%, 99% 3%, 99% 95%, 0 95%, 0 100%, 100% 100%, 100% -1%);
  background: linear-gradient(-45deg, #F7DD78, #D48DA6, #A279E6, #7976CE, #779BD4, #B7E9CD);
  background-size: 200% 200%;
  z-index: -1;
  animation: gradient 2s ease infinite, path-out 200ms ease-out forwards;
}

.btnChromaBorder:hover::before {
  /* clip-path:none; */
  animation: path-in 200ms ease-out forwards;
  z-index: -1;
}